import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { LandscapeYoutubePlayer } from "app/content-blocks/components/YoutubePlayer"
import MinimizedButton from "app/core/components/MinimizedButton"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { PX24 } from "theme/sizes"
import Group from "ui/Group"
import { Close, Content, Overlay, Root } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Title from "ui/Title"

export default function HomeModal() {
  const { state, toggle } = usePopoverSeen("HOME_NIEUWSFLITS_19")
  const modalActive = state === "OPENED"
  if (state === "CLOSED") {
    return null
  }
  return (
    <Dialog.Root
      open={modalActive}
      onOpenChange={(val) => toggle(val ? "OPENED" : "MINIMIZED")}
    >
      {
        <AnimatePresence>
          {!modalActive && (
            <Dialog.Trigger asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <MinimizedButton onClose={() => toggle("CLOSED")}>
                  <Value name="apps.web.src.home.components.homemodal.button">
                    Nieuwsflits #19
                  </Value>
                </MinimizedButton>
              </motion.div>
            </Dialog.Trigger>
          )}
        </AnimatePresence>
      }
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Root>
            <Content>
              <Stack gap="32px">
                <Title size={PX24}>
                  <Value name="apps.web.src.home.components.homemodal.title">
                    Nieuwsflits #19
                  </Value>
                </Title>
                <Stack gap="24px">
                  <LandscapeYoutubePlayer videoId={"Brtz6aoyrpQ"} />
                  <Stack>
                    <Value
                      name="apps.web.src.home.components.homemodal.body"
                      format={{
                        p: (c) => <p>{c}</p>,
                        li: (c) => <li>{c}</li>,
                        ul: (c) => <ul>{c}</ul>,
                        boekenLink: (c) => (
                          <Link
                            href={
                              "https://mijn.123zing.nl/categorieen/landelijke-themas/kinderboekenweek-2024"
                            }
                          >
                            {c}
                          </Link>
                        ),
                        webinarLink: (c) => (
                          <Link
                            href={"https://www.123zing.nl/webinarweek"}
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                        leeromgevingLink: (c) => (
                          <Link
                            href={
                              "https://123zing.my.site.com/knowledgebase/s/article/wat-is-er-nieuw-in-de-leeromgeving-2024-2025"
                            }
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                      }}
                    >
                      {`Bekijk de Nieuwsflits voor de laatste nieuwtjes over 123ZING! In deze Nieuwsflits:
                        <ul>
                            <li><boekenLink>Kinderboekenweek 2024: Lekker Eigenwijs</boekenLink></li>
                            <li><webinarLink>Webinar Week</webinarLink></li>
                            <li>Dans & Drama lesblok 1B online!</li>
                            <li><leeromgevingLink>Nieuw in de leeromgeving</leeromgevingLink></li>
                          </ul>`}
                    </Value>
                  </Stack>
                </Stack>
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton variant="filled">
                      <Value name="apps.web.src.news.components.home.sluiten">
                        Later bekijken
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  {/* <RoundedButton variant="filled">
                    <Value name="apps.web.src.news.components.home.meer_informatie">
                      Meer informatie
                    </Value>
                  </RoundedButton> */}
                </Group>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
